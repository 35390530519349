<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-wrap mb-2 w-full lg:w-1/2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <div class="w-full lg:w-1/2 pb-4">
      <form @submit.prevent="store({...teacher, is_coordinator: isCoordinator})" @keydown="removeError($event.target.id)">
        <h3 class="text-2xl my-4">Crear {{ isCoordinator ? 'coordinador' : 'profesor' }}</h3>

        <div class="flex flex-col space-y-4">

          <a-photo-selector v-model="teacher.profile_picture" :loading="loading" />

          <h4 class="text-xl font-bold text-gray-800 mb-4">
            Información básica
          </h4>

          <a-input
            label="Nombres"
            required
            class="my-4"
            id="first_name"
            placeholder="Nombres"
            :error="error('first_name', { attribute: 'nombres' })"
            v-model="teacher.first_name"/>

          <a-input
            label="Apellidos"
            required
            class="mb-4"
            id="last_name"
            placeholder="Apellidos"
            :error="error('last_name', { attribute: 'apellidos' })"
            v-model="teacher.last_name"/>

          <a-input
            label="Profesión"
            required
            class="mb-4"
            id="profession"
            placeholder="Profesión"
            :error="error('profession', { attribute: 'profesión' })"
            v-model="teacher.profession"/>

          <a-input
            label="Cédula de identidad"
            class="mb-4"
            required
            id="id_document"
            placeholder="Cédula de identidad"
            :error="error('id_document', { attribute: 'cédula de identidad' })"
            v-model="teacher.id_document"/>

          <h4 class="text-xl font-bold text-gray-800 mb-4 pt-4">
            Información de contacto
          </h4>

          <a-input
            label="Correo electrónico"
            id="email"
            required
            class="mb-4"
            placeholder="Correo electrónico"
            :error="error('email', { attribute: 'correo electrónico' })"
            v-model="teacher.email"/>

          <a-input
            label="Teléfono de habitación"
            class="mb-4"
            required
            id="phone"
            placeholder="Teléfono de habitación"
            :error="error('phone', { attribute: 'teléfono de habitación' })"
            v-model="teacher.phone"/>

          <a-input
            label="Teléfono celular"
            class="mb-4"
            required
            id="mobile_phone"
            placeholder="Teléfono celular"
            :error="error('mobile_phone', { attribute: 'teléfono celular' })"
            v-model="teacher.mobile_phone"/>

          <a-input
            type="textarea"
            label="Dirección"
            class="mb-4"
            required
            id="address"
            placeholder="Dirección"
            :error="error('address', { attribute: 'dirección' })"
            v-model="teacher.address"/>

          <label class="mb-2 block text-gray-700 font-medium">
            ¿Esta activo?
          </label>
          <a-switch v-model="teacher.enabled">
            <template #prefix>
              No
            </template>
            <template #suffix>
              Si
            </template>
          </a-switch>

          <template v-if="isCoordinator">
            <a-select
              class="mt-2"
              label="Especialidad"
              @input="removeError('specialization_id')"
              :loading="loadings"
              :source="specializations"
              :error="error('specialization_id', { attribute: 'especialidad' })"
              v-model="teacher.specialization_id"/>
          </template>
        </div>

        <div class="flex flex-wrap space-x-2 mt-4">
          <a-button class="mr-2" type="submit" mode="primary" :loading="loading">
            {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
          </a-button>

          <a-button outlined :to="{ name: 'teachers.index' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  props: {
    isCoordinator: { required: false, type: Boolean, default: false }
  },
  data: () => ({
    picture: null,
    isUpdating: false,
    teacher: {
      is_coordinator: 0,
      enabled: true,
      courses: [],
    },
    courses: [],
    specializations: [],
    loadings: false
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    ...mapState({
      teachers: state => state.teachers.pagination.data,
      currentTeacherOnState: ({ teachers }) => teachers.current,
      loading: state => state.teachers.loading,
      loadingCourses: state => state.courses.loading,
      loadingSpecialization: state => state.specializations.loading,
    })
  },
  methods: {
    ...mapActions({
      store: 'teachers/store',
      removeError: 'removeError',
      fetchTeacher: 'teachers/show',
      update: 'teachers/update',
      updateProfilePicture: 'teachers/updateProfilePicture',
      fetchCourses: 'courses/index',
    }),
    dropPhoto() {
      this.teacher.profile_picture = null
      this.picture = null
      document.querySelector('#file_input').value = null
    },
    handlePhotoUpdate() {
      if (this.picture) {
        this.updateProfilePicture(this.teacher.profile_picture)
      }
    }
  },
  watch: {
    currentTeacherOnState(val) {
      if (val) {
        delete val.email
        this.teacher = {
          ...val,
          courses: val.courses?.map(el => el.id)
        }
      }
    }
  },
  mounted() {
    // this.fetchCourses({ limit: 0 })
    this.loadings = !this.loadings
    if (this.isCoordinator) {
      Promise.all([
        this.$repository.specializations
          .index({ limit: 0 })
      ])
        .then(([{ data: specializations }]) => {
          Promise.all([
            this.$repository.teachers
              .indexCoordinators({ limit: 0 })
          ])
            .then(([{data: coordinators}]) => {
              const coordinatorSpecializations = coordinators.flatMap(a=> a.specializations)
              const fiteredSpecializations = specializations
              .filter(specialization => 
              !coordinatorSpecializations.some(coordinatorSpec => coordinatorSpec.id === specialization.id))
              this.specializations = fiteredSpecializations.map(el => ({ label: el.title, value: el.id }))
            })
        })
        .finally(() => this.loadings = !this.loadings)
    }
    // this.fetchSpecializations({ limit: 0 })
  }
}
</script>
